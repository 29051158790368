
::v-deep {
    td.text-truncate {
        max-width: 0px;
    }
    td.white-space-pre-line {
        white-space: pre-line;
        line-height: 1.25;
    }
}
