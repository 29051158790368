
.unit-list {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
}
.unit-item {
    padding: 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
