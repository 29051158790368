
.files-text-field ::v-deep {
    .v-text-field__slot > input {
        display: none !important;
    }
    .v-input__append-inner {
        position: absolute;

        margin-top: 2px !important;
        padding: 0 !important;
    }
}
