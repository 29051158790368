
::v-deep {
    td.text-truncate {
        max-width: 0;
    }
    td.text-align-right {
        text-align: right !important;
    }
    td.white-space-pre-line {
        white-space: pre-line;
    }
}
