
::v-deep {
    .country-selector,
    .country-selector__input,
    .input-tel,
    .input-tel__input {
        height: 52px !important;
        min-height: 52px !important;
    }
}
