
::v-deep .v-input__prepend-inner {
    width: 100px;
    margin-top: 4px !important;
}
::v-deep .v-input__prepend-inner .v-input__slot {
    &::before,
    &::after {
        display: none;
    }
    .v-input__append-inner {
        margin: 3px 0 !important;
    }
}
.inner-select {
    margin: 0 !important;
    padding: 0 !important;
}
::v-deep td.text-truncate {
    max-width: 0;
}
