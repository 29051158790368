
.switch-input-field ::v-deep {
    input {
        cursor: pointer;
        padding: 8px;
    }
    .v-input__slot {
        cursor: initial;
    }
}
