
::v-deep {
    td.text-truncate {
        max-width: 0 !important;
    }
    td.line-height-1-2 {
        line-height: 1.2 !important;
    }
    td.white-space-pre {
        white-space: pre !important;
    }
    td.font-family-monospace {
        font-family: "NanumGothicCoding", "DejaVu Sans Mono", monospace !important;
    }
    .white-space-pre-line {
        white-space: pre-line !important;
    }
}
